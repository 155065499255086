import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import { breakpoints } from "./layouts";

const Typography = () => {
  const scaleRatio = 1.414;
  const fontSize = 1;
  const lineHeight = 1.5;

  return {
    h1: css`
      font-size: ${calcFontSize(3.5)}rem;
      line-height: ${lineHeight};
      font-feature-settings: "palt";

      @media ${breakpoints.handheld} {
        font-size: ${calcFontSize(2)}rem;
      }
    `,
    h2: css`
      font-size: ${calcFontSize(2.5)}rem;
      line-height: ${lineHeight};
      font-feature-settings: "palt";

      @media ${breakpoints.handheld} {
        font-size: ${calcFontSize(1.75)}rem;
      }
    `,
    h3: css`
      font-size: ${calcFontSize(1.5)}rem;
      line-height: ${lineHeight};
      font-feature-settings: "palt";

      @media ${breakpoints.handheld} {
        font-size: ${calcFontSize(1.25)}rem;
      }
    `,
    h4: css`
      font-size: ${calcFontSize(1)}rem;
      line-height: ${lineHeight};
      font-feature-settings: "palt";

      @media ${breakpoints.handheld} {
        font-size: ${calcFontSize(1)}rem;
      }
    `,
    h5: css`
      font-size: ${fontSize}rem;
      line-height: ${lineHeight};
      font-feature-settings: "palt";

      @media ${breakpoints.handheld} {
        font-size: ${fontSize * .875}rem;
      }
    `,
    h6: css`
      font-size: ${fontSize}rem;
      line-height: ${lineHeight};
      font-feature-settings: "palt";

      @media ${breakpoints.handheld} {
        font-size: ${fontSize * .875}rem;
      }
    `,
    p: css`
      font-size: ${fontSize}rem;
      line-height: ${lineHeight};
      font-feature-settings: "palt";

      @media ${breakpoints.handheld} {
        font-size: ${fontSize * .875}rem;
      }
    `
  }

  function calcFontSize(ratio) {
    return fontSize * (scaleRatio ** ratio);
  }
}

export default Typography();

export const Heading1 = styled.h1`
  ${Typography().h1}
`;

export const Heading2 = styled.h2`
  ${Typography().h2}
`;

export const Heading3 = styled.h3`
  ${Typography().h3}
`;

export const Heading4 = styled.h4`
  ${Typography().h4}
`;

export const Heading5 = styled.h5`
  ${Typography().h5}
`;

export const Heading6 = styled.h6`
  ${Typography().h6}
`;

export const BR = () => {
  return <br css={ResponsiveBrStyle} />
}

const ResponsiveBrStyle = css`
  @media ${breakpoints.sm} {
    display: none;
  }
  @media ${breakpoints.xs} {
    display: none;
  }
`;
